<template>
  <div class="box fadeIn">
    <van-nav-bar
      :title="title"
      fixed
      left-arrow
      @click-left="$router.back()"
      z-index="999"
    />
    <div class="box_item">
      <span style="font-weight: 700">设备运行状态时序图</span
      ><span v-if="isrunStatus">
        <van-tag color="#32a756">运行{{ StateRatio.run }}%</van-tag>
        <van-tag color="#f3591c" style="margin: 0 5px"
          >异常{{ StateRatio.warning }}%</van-tag
        >
        <van-tag color="#a7dc5a">待机{{ StateRatio.wait }}%</van-tag>
      </span>
      <van-icon
        name="underway-o"
        @click="show = true"
        color="#5470c6"
        style="margin-left: 5px"
        size="16"
      />
      <van-popup
        v-model="show"
        round
        position="bottom"
        :style="{ height: '50%' }"
      >
        <van-datetime-picker
          v-model="oeeDate"
          type="month-day"
          title="选择月日"
          :min-date="minDate"
          :max-date="maxDate"
          @cancel="show = false"
          @confirm="getOee(), (show = false)"
        />
      </van-popup>
      <div style="width: 100%; height: 80px">
        <van-empty
          image-size="50"
          v-if="!isrunStatus"
          class="custom-image"
          image="https://ts1.cn.mm.bing.net/th/id/R-C.6653e44964f0b978d5098623a6397a64?rik=%2f4MtTbzxeGuCgQ&riu=http%3a%2f%2fbestfyl.com%2f_nuxt%2fimg%2f6653e44.png&ehk=M0yxp9nl50R42hNcrfIxz1rH3e34sQiC858q5FSZtmI%3d&risl=&pid=ImgRaw&r=0"
          description="暂无数据"
        />
        <div v-else id="oeeBody" ref="oeeBody" class="echarts_box"></div>
      </div>
    </div>
    <!-- 运动状态 -->
    <div class="box_item">
      <h3>运行状态能耗产量复合图</h3>
      <div style="width: 100%; height: 150px">
        <div class="echarts_box" id="yield" v-if="loading"></div>
        <van-loading color="#0094ff" vertical v-else>加载中...</van-loading>
      </div>
    </div>
    <!-- 运行时长统计 -->
    <div class="box_item">
      <H3>运行时长统计</H3>
      <div style="width: 100%; height: 150px">
        <div class="echarts_box" id="DurationAll"></div>
      </div>
    </div>
    <!-- 健康度 -->
    <div class="box_item">
      <h3>健康度</h3>
      <van-row>
        <van-col :span="7" style="position: relative">
          <van-circle
            size="80"
            v-model="realData.QST * 1"
            color="red"
            :clockwise="false"
            layer-color="#e8eaed"
          />
          <span
            style="
              display: inline-block;
              position: absolute;
              width: 80px;
              top: 15px;
              left: 4px;
              text-align: center !important;
            "
          >
            <span style="color: #666; font-size: 20px">{{ realData.QST }}</span
            ><br />
            <span style="color: #52cdd9; font-size: 18px">总评分</span></span
          >
        </van-col>
        <van-col :span="6" style="padding-top: 20px">
          <h5 style="text-decoration: underline">月最大需量</h5>
          <h2 style="margin: 5px 10px; color: #1193f1">{{ realData.MMP }}Kw</h2>
        </van-col>
        <van-col :span="11" style="display: flex; flex-wrap: wrap">
          <el-row style="width: 50%; display: flex; margin-bottom: 8px">
            <div
              style="
                width: 10px;
                height: 30px;
                background-color: #f5640a;
                border-radius: 5px;
              "
            ></div>
            <div class="percent">
              <p>{{ realData.QS4 }}%</p>
              <span>相角差</span>
            </div>
          </el-row>
          <el-row style="width: 50%; display: flex">
            <div
              style="
                width: 10px;
                height: 30px;
                background-color: #66c603;
                border-radius: 5px;
              "
            ></div>
            <div class="percent">
              <p>{{ realData.QS1 }}%</p>
              <span>电压平衡度</span>
            </div>
          </el-row>
          <el-row style="width: 50%; display: flex">
            <div
              style="
                width: 10px;
                height: 30px;
                background-color: #fe4f4f;
                border-radius: 5px;
              "
            ></div>
            <div class="percent">
              <p>{{ realData.QS3 }}%</p>
              <span>功率因数</span>
            </div>
          </el-row>
          <el-row style="width: 50%; display: flex">
            <div
              style="
                width: 10px;
                height: 30px;
                background-color: #0890f1;
                border-radius: 5px;
              "
            ></div>
            <div class="percent">
              <p>{{ realData.QS2 }}%</p>
              <span>电流平衡度</span>
            </div>
          </el-row>
        </van-col>
      </van-row>
    </div>
    <!-- 能效统计 -->
    <div class="box_item">
      <h3>能效统计</h3>
      <van-row>
        <van-col :span="10" style="position: relative">
          <van-circle
            size="80"
            v-model="realData.EEF * 1"
            color="red"
            :clockwise="false"
            layer-color="#e8eaed"
          />
          <span
            style="
              display: inline-block;
              position: absolute;
              width: 80px;
              top: 15px;
              left: 3px;
              text-align: center !important;
            "
          >
            <span style="color: #666; font-size: 20px">{{ realData.EEF }}</span
            ><br />
            <span style="color: #000; font-size: 18px">日能效</span></span
          >
        </van-col>
        <van-col :span="14" style="display: flex; flex-wrap: wrap">
          <el-row style="width: 50%; display: flex; margin-bottom: 8px">
            <div
              style="
                width: 10px;
                height: 30px;
                background-color: #f5640a;
                border-radius: 5px;
              "
            ></div>
            <div class="percent">
              <p>{{ realData.DRT }}</p>
              <span>日累计运行时长</span>
            </div>
          </el-row>
          <el-row style="width: 50%; display: flex">
            <div
              style="
                width: 10px;
                height: 30px;
                background-color: #66c603;
                border-radius: 5px;
              "
            ></div>
            <div class="percent">
              <p>{{ realData.DE }}</p>
              <span>日累计能耗</span>
            </div>
          </el-row>
          <el-row style="width: 50%; display: flex">
            <div
              style="
                width: 10px;
                height: 30px;
                background-color: #fe4f4f;
                border-radius: 5px;
              "
            ></div>
            <div class="percent">
              <p>{{ realData.MRT }}</p>
              <span>月累计运行时长</span>
            </div>
          </el-row>
          <el-row style="width: 50%; display: flex">
            <div
              style="
                width: 10px;
                height: 30px;
                background-color: #0890f1;
                border-radius: 5px;
              "
            ></div>
            <div class="percent">
              <p>{{ realData.ME }}</p>
              <span>月累计能耗</span>
            </div>
          </el-row>
        </van-col>
      </van-row>
      <div style="height: 100px; width: 100%">
        <div id="Energycurve" class="echarts_box"></div>
      </div>
    </div>
    <!-- 产量分析 -->
    <div class="box_item">
      <h3>产量分析</h3>
      <van-row type="flex" style="justify-content: space-evenly">
        <van-row style="display: flex; margin-bottom: 8px">
          <div
            style="
              width: 10px;
              height: 30px;
              background-color: #f5640a;
              border-radius: 5px;
            "
          ></div>
          <div class="percent">
            <p>{{ realData.DOT }}</p>
            <span>日累计产量</span>
          </div>
        </van-row>
        <van-row style="display: flex">
          <div
            style="
              width: 10px;
              height: 30px;
              background-color: #66c603;
              border-radius: 5px;
            "
          ></div>
          <div class="percent">
            <p>{{ realData.MOT }}</p>
            <span>月累计产量</span>
          </div>
        </van-row>
        <van-row style="display: flex">
          <div
            style="
              width: 10px;
              height: 30px;
              background-color: #fe4f4f;
              border-radius: 5px;
            "
          ></div>
          <div class="percent">
            <p>{{ realData.IOR }}</p>
            <span>日投入产出</span>
          </div>
        </van-row>
        <van-row style="display: flex">
          <div
            style="
              width: 10px;
              height: 30px;
              background-color: #0890f1;
              border-radius: 5px;
            "
          ></div>
          <div class="percent">
            <p>{{ realData.PEF }}</p>
            <span>日生产效率</span>
          </div>
        </van-row>
      </van-row>
      <div style="height: 100px; width: 100%">
        <div class="echarts_box" id="Yieldanalysis"></div>
      </div>
    </div>
    <!-- 日投入产出、日生产效率曲线 -->
    <div class="box_item">
      <h3>日投入产出、日生产效率曲线</h3>
      <div style="width: 100%; height: 150px">
        <div class="echarts_box" id="OutputCurve"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
let that;
export default {
  data() {
    return {
      minDate: new Date(2023, 0, 1),
      maxDate: new Date(),
      oeeDate: new Date(),
      show: false,
      isPower: true,
      isvoltage: true,
      realData: {
        SBY1: "",
        RST: "",
        DOT: "",
        MOT: "",
        IOR: "",
        PEF: "",
        QST: "",
        MMP: "",
        QS1: "",
        QS2: "",
        QS3: "",
        QS4: "",
        EEF: "",
        DRT: "",
        DE: "",
        MRT: "",
        ME: "",
      },
      StateRatio: {
        wait: "",
        run: "",
        warning: "",
      },
      isrunStatus: false,
      flag: false,
      title: this.$route.query.pjName,
      healthData: {
        QST: "",
        QS4: "",
        QS3: "",
        QS2: "",
        QS1: "",
      },
      dateNow: "",
      date: {
        day: "",
        week: "",
        month: "",
      },
      zoom: 1,
      loading: false,
      //健康度
      sethealth(data) {
        var dataHigh = data[0].value;
        var total = 100;
        var perHigh = ((dataHigh / total) * 100).toFixed(0) + "%";
        let option = {
          title: [
            {
              text: data[0].name,
              x: "21%",
              top: "52%",
              // 大小可改
              textStyle: {
                color: "#4dbcb4",
                fontSize: 30,
              },
            },
            {
              text: data[0].value,
              x: "23%",
              top: "30%", // 大小可改
              textStyle: {
                fontSize: 30,
                color: "#7f7f7f",
              },
            },
          ],
          series: [
            {
              name: "低级风险",
              type: "pie",
              clockWise: false,
              roundCap: true,
              center: ["30%", "52%"],
              radius: ["70%", "75%"],
              label: {
                show: false,
              },
              itemStyle: {},
              labelLine: {
                show: false,
              },
              hoverAnimation: false,
              data: [
                {
                  value: dataHigh,
                  name: "",
                  itemStyle: {
                    normal: {
                      color: "red",
                    },
                  },
                },
                {
                  name: "",
                  itemStyle: {
                    emphasis: {
                      color: "#e6e9f0",
                    },
                    normal: {
                      color: "#e6e9f0",
                    },
                  },
                  value: total - dataHigh, // 总数减去当前项数(灰色占比)
                },
              ],
            },
          ],
        };
        var chartDom = document.getElementById("HealthDegree");
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
      },
      sethistory(xData, yData, ref) {
        let title = "";
        let bottom = "5%";
        let data = yData.map((item) => item.name);
        if (ref == "Yieldanalysis") {
          title = "日产量曲线";
          bottom = "20%";
          data = [];
        } else if (ref == "Energycurve") {
          title = "日能效曲线";
          bottom = "20%";
          data = [];
        }
        let option = {
          title: {
            text: title,
            textStyle: {
              fontSize: "14",
            },
            bottom: "bottom",
            left: "center",
          },
          tooltip: {
            trigger: "axis",
            extraCssText: "z-index:999999",
            confine: true,
          },
          dataZoom: [
            {
              type: "inside",
              start: 0,
              end: 100,
            },
          ],
          legend: {
            data: data,
            right: "2%",
            orient: "vertical",
            icon: "circle",
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: bottom,
            top: "5%",
            containLabel: true,
          },
          // toolbox: {
          //   feature: {
          //     saveAsImage: {}
          //   }
          // },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: xData,
          },
          yAxis: {
            type: "value",
          },
          series: yData,
        };
        var chartDom = document.getElementById(ref);
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
      },
      setOee(data) {
        function renderItem(params, api) {
          var categoryIndex = api.value(0);
          var start = api.coord([api.value(1), categoryIndex]);
          var end = api.coord([api.value(2), categoryIndex]);
          var height = api.size([0, 1])[1] * 0.7;
          var rectShape = echarts.graphic.clipRectByRect(
            {
              x: start[0],
              y: start[1] - height / 2,
              width: end[0] - start[0],
              height: height,
            },
            {
              x: params.coordSys.x,
              y: params.coordSys.y,
              width: params.coordSys.width,
              height: params.coordSys.height,
            }
          );
          return (
            rectShape && {
              type: "rect",
              transition: ["shape"],
              shape: rectShape,
              style: api.style(),
            }
          );
        }
        let option = {
          tooltip: {
            trigger: "item",
            // position: "bottom",
            confine: true,
            formatter: function (params) {
              let date = new Date(params.value[1]);
              let Y = date.getFullYear() + "-";
              let M =
                (date.getMonth() + 1 < 10
                  ? "0" + (date.getMonth() + 1)
                  : date.getMonth() + 1) + "-";
              let D =
                (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
                " ";

              let h =
                (date.getHours() < 10
                  ? "0" + date.getHours()
                  : date.getHours()) + ":";
              let m =
                (date.getMinutes() < 10
                  ? "0" + date.getMinutes()
                  : date.getMinutes()) + ":";
              let s =
                date.getSeconds() < 10
                  ? "0" + date.getSeconds()
                  : date.getSeconds();
              let strDate = Y + M + D + h + m + s;
              strDate = strDate.replace(/-/g, "/");
              let date1 = new Date(params.value[2]);
              let str = params.marker + params.name + ": ";
              if (params.value[3] / 1000 / 3600 >= 1) {
                str +=
                  parseInt(
                    (params.value[3] % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                  ) + "时";
              }
              if (((params.value[3] / 1000) % 3600) / 60 >= 1) {
                str +=
                  parseInt((params.value[3] % (1000 * 60 * 60)) / (1000 * 60)) +
                  "分";
              }
              str +=
                (params.value[3] % (1000 * 60)) / 1000 +
                "秒" +
                `<br/>` +
                params.marker +
                params.name +
                ": " +
                `<br/>` +
                strDate +
                " - " +
                date1.toLocaleString("chinese", { hour12: false });
              if (!params.name) {
                str = "";
              }
              return str;
            },
            // axisPointer: {
            //   type: "cross",
            // },
          },
          dataZoom: [
            {
              type: "inside",
              filterMode: "weakFilter",
              showDataShadow: false,
              top: 0,
              labelFormatter: "",
              //  start: 99,
              // end: 100,
            },
          ],
          grid: {
            left: "5%",
            top: "2%",
            bottom: "30",
          },
          xAxis: {
            show: true,
            type: "time",
            boundaryGap: false,
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
          },
          yAxis: {
            data: [""],
            axisLine: {
              show: false,
            },
          },
          series: [
            {
              type: "custom",
              renderItem: renderItem,
              // itemStyle: {
              //   opacity: 1,
              // },
              encode: {
                x: [1, 2],
                y: 0,
              },
              data: data,
            },
          ],
        };
        var chartDom = this.$refs.oeeBody;
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
        this.chart = myChart;
      },
      setBar(data, Ydata, ref) {
        let option = {
          tooltip: {
            position: function (point, params, dom, rect, size) {
              // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
              // 提示框位置
              var x = 0; // x坐标位置
              var y = 0; // y坐标位置

              // 当前鼠标位置
              var pointX = point[0];
              var pointY = point[1];

              // 外层div大小
              // var viewWidth = size.viewSize[0];
              // var viewHeight = size.viewSize[1];

              // 提示框大小
              var boxWidth = size.contentSize[0];
              var boxHeight = size.contentSize[1];

              // boxWidth > pointX 说明鼠标左边放不下提示框
              if (boxWidth > pointX) {
                x = 5;
              } else {
                // 左边放的下
                x = pointX - boxWidth;
              }

              // boxHeight > pointY 说明鼠标上边放不下提示框
              if (boxHeight > pointY) {
                y = 5;
              } else {
                // 上边放得下
                y = pointY - boxHeight;
              }

              return [x, y];
            },
            trigger: "axis",
            axisPointer: {
              type: "cross",
            },
            formatter: function (params) {
              let formatterStr = "";
              formatterStr = params[0].axisValue + "<br/>";
              let arr = [];
              let all = "";
              let percentage = [];
              all = Number(
                params[0].data * 3600 +
                  params[1].data * 3600 +
                  params[2].data * 3600
              );
              params.forEach((item, index) => {
                let data = item.data * 3600;
                let str = "";
                if (data / 3600 >= 1) {
                  str = str + (data - (data % 3600)) / 3600 + "小时";
                }
                if ((data % 3600) / 60 >= 1) {
                  str = str + ((data % 3600) - (data % 60)) / 60 + "分";
                }
                str = str + Math.floor(data % 60) + "秒";
                arr.push(str);
                if (all == 0) {
                  percentage.push(0);
                } else {
                  percentage.push(((data / all) * 100).toFixed(2) * 1);
                }
                formatterStr +=
                  item.marker +
                  '<span style="display:inline-block;width:50px">' +
                  item.seriesName +
                  "</span>" +
                  '<span style="color:' +
                  item.color +
                  ';display:inline-block;margin:2px 0px 1px 30px">' +
                  arr[index] +
                  '<span style=" font-weight: 700;">' +
                  "(" +
                  percentage[index] +
                  "%" +
                  ")" +
                  "</span>" +
                  "</span>" +
                  "<br/>";
              });
              return "<div>" + formatterStr + "</div>";
            },
          },
          legend: {
            top: "1%",
            itemGap: 5,
          },
          grid: {
            top: "20%",
            left: "3%",
            right: "4%",
            bottom: "1%",
            containLabel: true,
          },
          dataZoom: [
            {
              type: "inside",
              xAxisIndex: [0],
              start: 0,
              end: 100,
            },
          ],
          xAxis: [
            {
              type: "category",
              data: Ydata,
              axisLabel: {
                //文字倾斜
                // interval: 0,
                // rotate: 20,
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              axisLabel: {
                formatter: "{value} 时",
              },
              splitNumber: 4,
              min: 0,
              max: 24,
            },
          ],
          series: data,
        };
        var chartDom = document.getElementById(ref);
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
      },
    };
  },
  methods: {
    getOee() {
      that.getYield(); //运动状态
      that.getOutputCurve();
      that.getEnergycurve(); //日能效
      that.getYieldanalysis();
      that.$api.energy
        .getEnergyOee({
          projectId: this.$route.query.projectId * 1,
          type: 1,
          shiftId: "",
          date: this.dayjs(this.oeeDate).format("YYYY-MM-DD"),
        })
        .then((res) => {
          if (res.code == 200) {
            if (res.data.length) {
              that.isrunStatus = true;
              let red = 0;
              let yellow = 0;
              let green = 0;
              let gray = 0;
              let allTime = 0;
              res.data.forEach((item) => {
                if (item.runStatus != 0) {
                  allTime += item.duration;
                }
                if (item.runStatus == 1) {
                  red += item.duration;
                } else if (item.runStatus == 2) {
                  yellow += item.duration;
                } else if (item.runStatus == 3) {
                  green += item.duration;
                } else {
                  gray += item.duration;
                }
              });
              this.StateRatio = {
                warning: ((red / allTime) * 100).toFixed(1),
                run: ((green / allTime) * 100).toFixed(1),
                wait: ((yellow / allTime) * 100).toFixed(1),
              };
            }
            this.setHietory(res.data);
          }
        });
    },
    setHietory(data) {
      data = data.reverse();
      if (data.length == 0) {
        let chartDom = document.getElementById("oeeBody");
        let myChart = echarts.init(chartDom);
        myChart.clear();
        return;
      }
      // 每天开始时间
      let startTime = +new Date(
        data[data.length - 1].startTime.split(" ")[0] + " " + "00:00:01"
      );
      // 每天的结束时间
      let endTime = +new Date(
        data[data.length - 1].startTime.split(" ")[0] + " " + "23:59:59"
      );
      let historyData = [];
      for (let i = data.length - 1; i >= 0; i--) {
        let date = +new Date(data[i].startTime); //每条数据上传的时间
        let item = data[i];
        let time = +new Date(data[data.length - 1].startTime); //第一次上传的时间
        if (i == data.length - 1 && startTime < time) {
          //如果每天的开始时间小于第一次上传的时间
          historyData.push({
            name: "",
            value: [
              "",
              startTime,
              (startTime += time - startTime),
              time - startTime,
            ],
            itemStyle: {
              color: "transparent",
            },
          });
        }
        let name, color;
        if (item.runStatus == 1) {
          name = "异常";
          color = "#f3591c";
        } else if (item.runStatus == 2) {
          name = "待机";
          color = "#a7dc5a";
        } else if (item.runStatus == 3) {
          name = "运行";
          color = "#32a756";
        } else if (item.runStatus == 0) {
          name = "离线";
          color = "gray";
        }
        historyData.push({
          name: name,
          value: [
            "",
            date,
            (date += item.duration * 1000),
            item.duration * 1000,
          ],
          itemStyle: {
            color: color,
          },
        });
        if (i == 0 && endTime > date + item.duration * 1000) {
          historyData.push({
            value: ["", date, (date += endTime - date), endTime - date],
            itemStyle: {
              color: "transparent",
            },
          });
        }
      }
      this.$nextTick(() => {
        this.setOee(historyData);
      });
    },
    getRealData() {
      let from = {
        projectId: this.$route.query.projectId * 1,
        paramTags: Object.keys(this.realData),
      };
      this.$api.energy.getParams(from).then((res) => {
        if (res.code == 200) {
          let arr = ["QS1", "QS2", "QS3", "QS4"];
          res.data.forEach((item) => {
            this.realData[item.paramTag] = item.value;
          });
          arr.forEach((item) => {
            this.realData[item] = (this.realData[item] * 1).toFixed(2);
          });
        }
      });
    },
    //运动状态能耗
    getYield() {
      let from = {};
      if (this.$route.query.projectType == "K2") {
        from = {
          date: this.dayjs().format("YYYY-MM-DD"),
          projectId: this.$route.query.projectId * 1,
          paramTags: ["ME", "DOT"],
          historyType: 1,
        };
      } else {
        from = {
          date: this.dayjs(this.oeeDate).format("YYYY-MM-DD"),
          projectId: this.$route.query.projectId * 1,
          paramTags: ["ME", "DOT"],
          historyType: 1,
        };
      }
      this.$api.energy.getHistoryData(from).then((res) => {
        that.loading = true;
        if (res.code == 200) {
          let xData = res.data.date;
          let yData = [];
          res.data.data.forEach((item) => {
            let newData = [];
            for (let key in item.stream) {
              newData.push([key, item.stream[key]]);
            }
            yData.push({
              smooth: true,
              name: item.name,
              type: "line",
              // stack: "Total",
              data: newData,
            });
          });
          this.$nextTick(() => {
            this.sethistory(xData, yData, "yield");
          });
        }
      });
    },
    // 日投入产出生产效率
    getOutputCurve() {
      let from = {};
      if (this.$route.query.projectType == "K2") {
        from = {
          date: this.dayjs().format("YYYY-MM-DD"),
          projectId: this.$route.query.projectId * 1,
          paramTags: ["IOR", "PEF"],
          historyType: 1,
        };
      } else {
        from = {
          date: this.dayjs(this.oeeDate).format("YYYY-MM-DD"),
          projectId: this.$route.query.projectId * 1,
          paramTags: ["IOR", "PEF"],
          historyType: 1,
        };
      }
      this.$api.energy.getHistoryData(from).then((res) => {
        if (res.code == 200) {
          let xData = res.data.date;
          let yData = [];
          res.data.data.forEach((item) => {
            let newData = [];
            for (let key in item.stream) {
              newData.push([key, item.stream[key]]);
            }
            yData.push({
              smooth: true,
              name: item.name,
              type: "line",
              // stack: "Total",
              data: newData,
            });
          });
          this.sethistory(xData, yData, "OutputCurve");
        }
      });
    },
    // 运行时长统计
    getTime() {
      let form = {
        projectId: this.$route.query.projectId * 1,
        startDate: this.dayjs(this.dayjs().subtract(7, "day").$d).format(
          "YYYY-MM-DD"
        ),
        endDate: this.dayjs().format("YYYY-MM-DD"),
      };
      this.$api.energy.getTimeDatile(form).then((res) => {
        if (res.code == 200) {
          let date = [];
          let red = [];
          let yellow = [];
          let green = [];
          let gray = [];
          res.data.forEach((item) => {
            let newGray = (item["0"] / 3600).toFixed(2) * 1;
            let newred = (item["1"] / 3600).toFixed(2) * 1;
            let newyellow = (item["2"] / 3600).toFixed(2) * 1;
            let newgreen = (item["3"] / 3600).toFixed(2) * 1;
            red.push(newred);
            yellow.push(newyellow);
            green.push(newgreen);
            date.push(item.date);
            gray.push(newGray);
          });
          let data = [
            {
              name: "异常",
              type: "bar",
              stack: "Ad",
              barMaxWidth: 40,
              emphasis: {
                focus: "none",
                disabled: true,
              },
              itemStyle: {
                color: "#f3591c",
              },
              data: red,
            },
            {
              name: "待机",
              type: "bar",
              stack: "Ad",
              barMaxWidth: 40,
              emphasis: {
                focus: "none",
                disabled: true,
              },
              itemStyle: {
                color: "#a7dc5a",
              },
              data: yellow,
            },
            {
              name: "运行",
              type: "bar",
              stack: "Ad",
              barMaxWidth: 40,
              emphasis: {
                focus: "none",
                disabled: true,
              },
              itemStyle: {
                color: "#32a756",
              },
              data: green,
            },
            {
              name: "离线",
              type: "bar",
              stack: "Ad",
              barMaxWidth: 40,
              emphasis: {
                focus: "none",
                disabled: true,
              },
              itemStyle: {
                color: "gray",
              },
              data: gray,
            },
          ];
          that.setBar(data, date, "DurationAll");
        }
      });
    },
    //日产量
    getYieldanalysis() {
      let from = {};
      if (this.$route.query.projectType == "K2") {
        from = {
          date: this.dayjs().format("YYYY-MM-DD"),
          projectId: this.$route.query.projectId * 1,
          paramTags: ["DOT"],
          historyType: 1,
        };
      } else {
        from = {
          date: this.dayjs(this.oeeDate).format("YYYY-MM-DD"),
          projectId: this.$route.query.projectId * 1,
          paramTags: ["DOT"],
          historyType: 1,
        };
      }
      this.$api.energy.getHistoryData(from).then((res) => {
        if (res.code == 200) {
          let xData = res.data.date;
          let yData = [];
          res.data.data.forEach((item) => {
            let newData = [];
            for (let key in item.stream) {
              newData.push([key, item.stream[key]]);
            }
            yData.push({
              smooth: true,
              name: item.name,
              type: "line",
              stack: "Total",
              data: newData,
              areaStyle: {
                color: {
                  type: "linear",
                  x: 0,
                  y: -0.2,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(137, 220, 229, 0.8)",
                    },
                    {
                      offset: 1,
                      //  color: 'rgba(255, 230, 0, 0.2)'
                      color: "rgba(137, 220, 229, 0.1)",
                    },
                  ],
                },
              },
            });
          });
          this.sethistory(xData, yData, "Yieldanalysis");
        }
      });
    },
    //日能效曲线
    getEnergycurve() {
      let from = {};
      if (this.$route.query.projectType == "K2") {
        from = {
          date: this.dayjs().format("YYYY-MM-DD"),
          projectId: this.$route.query.projectId * 1,
          paramTags: ["EEF"],
          historyType: 1,
        };
      } else {
        from = {
          date: this.dayjs(this.oeeDate).format("YYYY-MM-DD"),
          projectId: this.$route.query.projectId * 1,
          paramTags: ["EEF"],
          historyType: 1,
        };
      }
      this.$api.energy.getHistoryData(from).then((res) => {
        if (res.code == 200) {
          let xData = res.data.date;
          let yData = [];
          res.data.data.forEach((item) => {
            let newData = [];
            for (let key in item.stream) {
              newData.push([key, item.stream[key]]);
            }
            yData.push({
              smooth: true,
              name: item.name,
              type: "line",
              stack: "Total",
              data: newData,
              areaStyle: {
                color: {
                  type: "linear",
                  x: 0,
                  y: -0.2,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(137, 220, 229, 1)",
                    },
                    {
                      offset: 1,
                      //  color: 'rgba(255, 230, 0, 0.2)'
                      color: "rgba(137, 220, 229, 0.1)",
                    },
                  ],
                },
              },
            });
          });
          this.sethistory(xData, yData, "Energycurve"); //日能效
        }
      });
    },
  },
  created() {
    that = this;
  },
  mounted() {
    that.getOee(); //oee
    that.getRealData(); //实时数据
    that.getTime();
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-align: left !important;
}
.box {
  padding: 50px 10px 10px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  margin-bottom: 50px;
}
.echarts_box {
  width: 100%;
  height: 100%;
}
/* .custom-image ::v-deep .van-empty__image {
  width: 30px;
  height: 30px;
} */
.big {
  font-size: 18px;
}
.DateState {
  font-size: 16px !important;
  color: #5470c6;
  font-weight: 700;
}
/* 隐藏 Chrome、Safari 和 Opera 的滚动条 */
::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* 隐藏 IE、Edge 和 Firefox 的滚动条 */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
::v-deep .van-empty__description {
  font-size: 12px !important;
  margin-top: 0 !important;
}
.Panel {
  width: 97%;
  height: 100%;
  border: 1px solid #000;
  border-radius: 10px;
  margin: 0 10px;
  background: linear-gradient(to bottom, #9ae2e9, #e8f8fa);
}
.box_item1 .box {
  background-color: #fff;
  padding: 10px 10px;
}
.Panel_item {
  text-align: center !important;
  height: 25%;
  width: 100%;
  padding-left: 20px;
}
.Panel_item span {
  display: inline-block;
  width: 20%;
}
.percent {
  margin-left: 5px;
  text-align: center;
  line-height: 1;
}
.percent p {
  font-size: 16px;
  font-weight: 700;
}
.percent span {
  font-size: 12px;
  color: #666;
}
.box_item {
  margin-bottom: 10px;
}
.box_item h3 {
  margin-bottom: 5px;
}
</style>
